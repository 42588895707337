$blue: #4099da;
$red: #d9534f;
$green: #5cb85c;
$yellow: #f0ad4e;
$black: #337ab7;
$white: #fff;
$gray: #dddddd;

$lighten-amount: 10%;

$yellow-light: lighten($yellow, $lighten-amount);
$red-light: lighten($red, $lighten-amount);
$blue-light: lighten($blue, $lighten-amount);
$green-light: lighten($green, $lighten-amount);
$gray-light: lighten($gray, $lighten-amount);
