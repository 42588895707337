/* You can add global styles to this file, and also import other style files */

@import "orsted-sans";
@import "animations";
@import "material-icons";
@import "flex";
@import "input";
@import "theme";
@import "position";
@import "splitjs";

html,
body {
  height: 100%;
}

div {
  min-height: 0px;
  min-width: 0px;
}

body {
  font-family: "Roboto", "Tahoma", "sans-serif";
  margin: 0px;
  background-color: #f2f2f2;
  overflow: hidden;
}

// Fixing issue with mat-select
.mat-select-trigger {
  position: static !important;
}

.normal-padding {
  padding: 5px;
}

.active-link {
  background-color: #c7c7c7;
}

.toolbar-button {
  font-size: large;
  height: 100%;
  margin-right: 5px;

  @extend .flex-center-content;
  display: flex !important;
}

.danger:hover {
  color: red;
  background-color: $gray;
}

.primary:hover {
  color: $blue;
  background-color: $gray;
}
