.flex-center-content {
  display: flex;
  align-items: center; 
  align-content: center; 
  justify-content: center;
}

.flex-start-content {
  display: flex;
  align-items: center; 
  align-content: center; 
  justify-content: flex-start;
}

.flex-end-content {
  display: flex;
  align-items: center; 
  align-content: flex-end; 
  justify-content: flex-end;
}

.flex-row-direction {
  display: flex;
  flex-direction: row;
}

.flex-column-direction {
  display: flex;
  flex-direction: column;
}

@mixin flex-filling($basis: auto) {
  flex: 1 1 $basis;
}

.flex-filling-gap {
  @include flex-filling(auto);
}

.flex-fixed-size {
  flex: 0 0 auto;
}

.flex-align-start {
  align-self: flex-start;
}