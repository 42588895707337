input.cell {
   width: 60%;
   height: 26px;
   padding: 0px;
   text-align: center;
   font-weight: bold;
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   border-style: solid;
   border-width: 1px;
   border-color:  lightgray;

   &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: lightgray;
      font-weight: normal;
   }
}

input.invalid {
   border-color: $red;
   outline-color: $red;
}
