@font-face {
  font-family: "Orsted Sans Regular";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/orsted-sans/orsted-sans-regular.woff2) format("woff2");
}

.orsted-sans-regular {
  font-family: "Orsted Sans Regular";
}
