﻿@import "variables";

@keyframes flickerAnimation {
  0% {
    background-color: $white;
  }

  50% {
    background-color: $blue-light;
  }

  100% {
    background-color: $white;
  }
}

.puls-animation {
  animation: pulse 4s infinite;
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.fade-animation {
  animation: fading 4s infinite ease-in-out;
}

@keyframes fading {
  0% {opacity: 0.2} 
  50% {opacity: 1}
  100%{opacity: 0.2}
}

